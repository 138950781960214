<template>
  <div style="height: calc(100vh - 120px)">
    <!-- <div class="bg-danger">
      <div class="container">
        <div class="p-3 position-relative d-block">
          <button
            v-if="!isShowAlert"
            type="button"
            class="btn text-white position-absolute floating-btn d-flex"
            @click="handleBack"
          > -->
    <!-- <img class="icon-svg mr-1" src="../../../assets/arrow-left.svg" /> -->
    <!-- <i class="ri-arrow-left-line mr-2 align-middle"></i>
            <p class="m-0 align-middle">Back to Search</p> -->
    <!-- </button>
          <div class="text-center">
            <h4 class="text-white mb-0" v-if="!isShowAlert">
              Find your specialist
            </h4>
            <h4 class="text-white mb-0" v-else>
              Start Your New E-Prescription
            </h4>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container mt-5 box-container">
      <div class="mb-3 mx-1">
        <!-- <h5>Please choose from the list of doctors available.</h5> -->
        <h4 class="mb-3 ff-title fw-strong">Find your doctor</h4>
        <p class="text-muted ff-desc">
          Discover the best doctors to get immediate medical advice and/or a
          second opinion on your health issues. Consult with doctors online via
          video, phone call, or chat.
          <br />
          <br />
          Let’s get started!
        </p>
      </div>

      <!-- <table class="table table-custom mb-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col" style="width: 10%">Status</th>
          </tr>
        </thead>
      </table> -->

      <div class="fixed-height">
        <div
          v-for="(doctor, idx) in doctors"
          :key="doctor.id"
          @click="clickDoctor(doctor)"
          style="cursor: pointer"
          :style="doctor.is_online ? '' : 'opacity: 50%; cursor: no-drop;'"
        >
          <div
            class="card rounded-custom mb-2 mx-1"
            :style="
              idx % 2 != 0
                ? 'background-color: #f9fafb;'
                : 'background-color: #ffffff;'
            "
          >
            <div class="card-body" style="padding: 0.8rem">
              <div class="d-flex align-items-center">
                <div
                  class="img-thumbnails rounded-custom border overflow-hidden"
                >
                  <img
                    v-if="doctor && doctor.profile_img"
                    :src="getImageLink(doctor.profile_img)"
                    style="height: 100%; object-fit: cover"
                  />
                  <img
                    src="/img/person_placeholder_2.jpg"
                    style="height: 100%; object-fit: cover"
                  />
                </div>
                <div class="doctor-info">
                  <h6 class="ml-3 fw-stronger">{{ doctor.name }}</h6>
                  <span class="ml-3">{{ doctor.specialist }}</span>
                </div>
              </div>
              <div
                class="position-absolute dot rounded-circle"
                :class="doctor.is_online ? 'bg-success' : 'bg-danger'"
              ></div>
            </div>
          </div>
        </div>
        <!-- <table class="table table-custom">
          <tbody>
            <tr
              v-for="(doctor, idx) in doctors"
              :key="doctor.id"
              :style="
                idx % 2 != 0
                  ? 'background-color: #f9fafb;'
                  : 'background-color: #ffffff;'
              "
              @click="handleConsultation(doctor)"
            >
              <td class="border-0 pt-3 pb-3">
                <div class="d-flex align-items-center">
                  <div class="img-thumbnails rounded-circle">
                    <img
                      :src="getImageLink(doctor.profile_img)"
                      class="rounded-circle"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                  <div class="doctor-info">
                    <h6 class="ml-3">{{ doctor.name }}</h6>
                    <span class="ml-3">{{ doctor.specialist }}</span>
                  </div>
                </div>
              </td>
              <td class="border-0 pt-3 pb-3" style="width: 10%">
                <div
                  v-if="doctor.is_online"
                  class="badge badge-success badge-pill"
                >
                  Active
                </div>
                <div v-else class="badge badge-danger badge-pill">Inactive</div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
      <!-- <div class="mt-2 text-center">
        <button type="button" class="btn btn-light">Load More</button>
      </div> -->
    </div>

    <!-- Modal Request Consultation -->
    <div
      class="modal center"
      tabindex="-1"
      :style="
        showModalRequest
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content custom-radius">
          <div class="modal-body">
            <div class="content-custom-body">
              <div v-if="selectedDoc">
                <div
                  class="d-flex justify-content-center align-items-center w-100"
                >
                  <div
                    class="
                      img-container-profile
                      mb-4
                      custom-radius
                      overflow-hidden
                    "
                  >
                    <img
                      :src="getImageLink(selectedDoc.profile_img)"
                      style="height: 100%; object-fit: cover"
                    />
                  </div>
                </div>
                <div class="content-container w-100 text-center mb-2">
                  <h5 class="ff-title fw-strong">
                    {{ selectedDoc && selectedDoc.name }}
                  </h5>
                  <p class="text-muted small">
                    <span class="pr-2">{{ selectedDoc.specialist }}</span>
                    <span class="pl-2 border-left" v-if="clinicName">{{
                      clinicName != null ? clinicName : ""
                    }}</span>
                  </p>
                </div>
              </div>
              <div class="text-center mt-4" v-if="!showRequestTime">
                <div class="w-100 text-center ff-title">
                  <p class="">RM 15.00/consultation</p>
                  <!-- <p class="">RM {{ consultationPrice.toFixed(2) }}/consultation</p> -->
                </div>
              </div>
              <div class="text-center mt-4" v-if="showRequestTime">
                <div
                  class="
                    d-flex
                    w-100
                    align-items-center
                    justify-content-center
                    mt-4
                  "
                >
                  <p class="small text-muted mr-2" style="padding: 0.25rem">
                    Requesting
                  </p>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div
                      class="
                        alert
                        small
                        text-muted
                        bg-light
                        border-0
                        rounded-left
                      "
                      style="padding: 0.45rem 0.75rem; width: 35px"
                    >
                      {{ helper.displayMins(minTimer) }}
                    </div>
                    <div
                      class="small text-muted bg-light border-0"
                      style="padding: 0.45rem 0.15rem; margin-bottom: 1rem"
                    >
                      :
                    </div>
                    <div
                      class="alert small text-muted bg-light border-0"
                      style="padding: 0.45rem 0.75rem; width: 35px"
                    >
                      {{ helper.displaySec(secTimer) }}
                    </div>
                    <!-- <div
                      class="
                        alert
                        small
                        text-muted
                        bg-light
                        border-0
                        rounded-right
                      "
                      style="padding: 0.45rem 0.75rem 0.45rem 0.15rem"
                    >
                      min has passed
                    </div> -->
                  </div>
                </div>
                <button
                  type="button"
                  class="
                    btn btn-sm btn-cancel-req
                    text-center
                    mt-3
                    custom-radius
                  "
                  v-if="secTimer > 10 || minTimer > 0"
                  @click="clickConfirmation"
                >
                  Cancel Request
                </button>
              </div>
              <div class="text-center mt-4" v-else>
                <div class="action-container mt-4 w-100 d-flex flex-column">
                  <button
                    type="button"
                    class="
                      btn btn-primary
                      mb-2
                      button-primary-color-bg
                      w-100
                      custom-radius
                    "
                    @click="submitRequest()"
                  >
                    Request Consultation
                  </button>
                  <button
                    type="button"
                    @click="CloseModalRequest()"
                    class="btn btn-no w-100 btn-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Accepted Consultation -->
    <div
      class="modal center"
      tabindex="-1"
      :style="
        showModalAccepted
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content custom-radius">
          <div class="modal-body">
            <div class="content-custom-body">
              <div class="text-center">
                <div class="icon-close text-success">
                  <i class="ri-checkbox-circle-line"></i>
                </div>
                <h4 class="text-center ff-title fw-strong">Great!</h4>
                <p class="text-center text-muted ff-desc">
                  Your request has been accepted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Cancelled Consultation -->
    <div
      class="modal center"
      tabindex="-1"
      :style="
        showModalCancelled
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content custom-radius">
          <div class="modal-body">
            <div class="content-custom-body">
              <div class="text-center">
                <div class="icon-close text-warning">
                  <i class="ri-error-warning-line"></i>
                </div>
                <h4 class="text-center ff-title fw-strong">Are you sure?</h4>
                <p class="mb-4 text-center text-muted ff-desc">
                  Confirm to cancel your request.
                </p>
                <div class="text-center mt-4">
                  <button
                    type="button"
                    class="btn btn-light text-center mt-3 mr-2 custom-radius"
                    @click="showModalCancelled = false"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="
                      btn
                      button-primary-color-bg
                      text-white text-center
                      mt-3
                      custom-radius
                    "
                    @click="clickCancel"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Rejected || Not Responding -->
    <div
      class="modal center"
      tabindex="-1"
      :style="
        showModalRejected
          ? 'display: block; z-index:9999999; background-color: #4950579e;'
          : 'display: none'
      "
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content custom-radius">
          <div class="modal-body">
            <div class="content-custom-body">
              <div class="text-center">
                <div class="icon-close text-danger">
                  <i class="ri-close-circle-line"></i>
                </div>
                <h4 class="text-center ff-title fw-strong">
                  Request Rejected!
                </h4>
                <p class="mb-4 text-center text-muted ff-desc">
                  Your request has been rejected, <br />
                  Please make a new request with any other doctor.
                </p>
                <div class="text-center mt-4">
                  <button
                    type="button"
                    class="btn custom-radius btn-light text-center mt-3"
                    @click="closeModalRejected"
                  >
                    Choose Another
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import API from "./../../../utils/API";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Helper from "../../../utils/helper";
import maleAvatar from "../../../assets/male_avatar.png";
import io from 'socket.io-client'

export default {
  name: "RegisterForm",
  data() {
    return {
      consultationPrice: 15.00,
      isShowAlert: false,
      doctors: [],
      isShowModal: false,
      selectedDoc: null,
      isRequestingConsultation: false,
      timeout: 0,
      clinicName: null,
      getRequestStatus: "",
      hourTimer: 0,
      minTimer: 0,
      secTimer: 0,
      hourReq: 0,
      minReq: 0,
      requestCount: 0,
      helper: Helper,
      reqStatus: "request_new",
      countDownTimer: 3,
      redirectTimer: 0,
      isPaused: false,
      callDoc: false,
      callConsultation: false,
      showModalRequest: false,
      showRequestTime: false,
      showModalAccepted: false,
      showModalCancelled: false,
      showModalRejected: false,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer", "consultation"]),
  },
  watch: {
    secTimer: {
      handler() {
        const localTimers = localStorage.getItem("timer_request_doc");
        if (this.secTimer == 60) {
          this.minTimer = this.minTimer == 60 ? 0 : this.minTimer + 1;
        } else if (localTimers) {
          localStorage.setItem(
            "timer_request_doc",
            `${this.hourTimer}:${this.minTimer}:${this.secTimer}`
          );
        } else if (this.secTimer != 0) {
          localStorage.setItem(
            "timer_request_doc",
            `${this.hourTimer}:${this.minTimer}:${this.secTimer}`
          );
        }
      },
      immediate: false,
    },
    minTimer: {
      handler() {
        if (this.minTimer == 60) {
          this.hourTimer = this.hourTimer + 1;
        }

        if (this.minTimer >= 1 && this.secTimer >= 30) {
          this.reqStatus = "auto_reject";
          this.forceCancelRequestConsultation();
        }
      },
      immediate: false,
    },
    countDownTimer: {
      handler() {
        if (this.countDownTimer == 0) {
          clearInterval(this.redirectTimer);
          this.countDownTimer = 0;
          this.$router.push({
            path: "/e-prescription/consultation",
          });
        }
      },
    },
    showModalRequest: {
      handler() {
        if (!this.showModalRequest) {
          clearInterval(this.callConsultation);
          clearInterval(this.requestCount);
          localStorage.removeItem("timer_request_doc");
          localStorage.setItem("consultation", null);
          this.showRequestTime = false;
          this.hourTimer = 0;
          this.minTimer = 0;
          this.secTimer = 0;
          this.hourReq = 0;
          this.minReq = 0;
          this.secReq = 0;
          this.intervalDoc();
        } else {
          // clearInterval(this.callDoc);
        }
      },
    },
    showModalAccepted: {
      handler() {
        if (this.showModalAccepted) {
          setInterval(() => {
            clearInterval(this.callConsultation);
            clearInterval(this.requestCount);
            // clearInterval(this.callDoc);
            localStorage.removeItem("timer_request_doc");
            this.showModalAccepted = false;

            // this.$router.push({
            //   path: "/e-prescription/consultation",
            // });
            return window.location.href = '/e-prescription/consultation';
          }, 3000);
        }
      },
    },
    showModalCancelled: {
      handler() {
        if (this.showModalCancelled) {
          this.isPaused = true;
        } else {
          this.isPaused = false;
          // clearInterval(this.callConsultation);
          // clearInterval(this.requestCount);
          // localStorage.removeItem("timer_request_doc");
          // this.intervalDoc();
        }
      },
    },
    showModalRejected: {
      handler() {
        if (!this.showModalRejected) {
          clearInterval(this.callConsultation);
          clearInterval(this.requestCount);
          localStorage.removeItem("timer_request_doc");
          this.intervalDoc();
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "setNewConsultation",
      "createConsultationPayment",
      "setActiveConsultation",
    ]),
    ...mapMutations(["setConsultation"]),

    closeModalRejected() {
      this.showModalRejected = false;
    },

    clickConfirmation() {
      this.showModalCancelled = true;
    },

    async clickCancel() {
      await this.forceCancelRequestConsultation();
      this.CloseModalRequest();
    },

    forceCancelRequestConsultation() {
      return new Promise (async(resolve, reject) => {
        const resConsult = await API.put(
          `/consultations/${this.consultation.id}`,
          {
            status: "cancelled",
          }
        );

        if (resConsult.status == 200) {
          this.showModalCancelled = false;
          this.CloseModalRequest();
        }

        resolve(0)
      })
    },

    async submitRequest() {
      await this.setNewConsultation({
        doctorID: this.selectedDoc.id,
        customer: this.currentCustomer,
      });

      this.showRequestTime = true;
      this.countRequestTimer();

      if (this.consultation) this.intervalConsult();
    },

    countRequestTimer() {
      this.requestCount = setInterval(() => {
        if (!this.isPaused) {
          let count = this.secTimer == 60 ? 0 : this.secTimer;
          this.secTimer = count + 1;
        }
      }, 1000);
    },

    CloseModalRequest() {
      this.selectedDoc = null;
      this.clinicName = null;
      this.showModalRequest = false;
    },

    clickDoctor(doctorDetails) {
      if (doctorDetails.is_online) {
        this.showModalRequest = true;
        this.selectedDoc = doctorDetails;
        this.clinicName = !doctorDetails.corporate_organisation
          ? ""
          : doctorDetails.corporate_organisation.org_name;
      }
    },

    getImageLink(_img) {
      if(!_img || !_img.url)
        return '/img/person_placeholder_2.jpg'
        
      return !_img ? null : _img && API.getAPIUrl() + _img.url;
    },

    intervalConsult() {
      if (this.consultation) {
        this.callConsultation = setInterval(async () => {
          const resConsult = await API.get(
            `/consultations/${this.consultation.id}`
          );
          if (resConsult.data.status == "accepted") {
            // this.setActiveConsultation({
            //   userID: this.currentCustomer.user.id,
            // });

            this.CloseModalRequest();
            this.showModalAccepted = true;
          } else if (resConsult.data.status == "cancelled") {
            this.showModalRejected = true;
          }
        }, 2000);
      }
    },

    async intervalDoc() {
      // this.callDoc = setInterval(async () => {
      //   const resDoc = await API.get("/doctors?_sort=is_online:DESC");
      //   this.doctors = resDoc.data;
      // }, 1000);
      const resDoc = await API.get("/doctors?_sort=is_online:DESC");
      this.doctors = resDoc.data.filter(doc => doc.name !== "Dr. Aizad" && doc.name !== "Dr. Razi" && (doc.user && doc.user.blocked == false));
    },

    async getConsultation() {
      const response = await API.get(
      `/consultations?customer_id=${this.currentCustomer.user.customer.id}`
    );

      if (response.data.length > 0 && (response.data[0].status == "accepted" || response.data[0].status == "completed") && response.data[0].payment_status !== "paid") {
        // this.$router.push({
        //   path: "/e-prescription/consultation",
        // });
        return window.location.href = '/e-prescription/consultation';
      } else {
        const resDoc = await API.get("/doctors?_sort=is_online:DESC");
        this.doctors = resDoc.data.filter(doc => doc.name !== "Dr. Aizad" && doc.name !== "Dr. Razi" && (doc.user && doc.user.blocked == false));
      }
      // if (response.data.length > 0 && response.data[0].status == "pending") {
      //   this.showModalRequest = true;
      //   this.clickDoctor(this.consultation.doctor);
      //   this.showRequestTime = true;
      //   this.countRequestTimer();
    },

    async initSocket(){
      const socket = io(API.getAPIUrl(), {
          auth: {
              'APP_KEY': process.env.VUE_APP_SOCKET_APP_KEY,
              'APP_SECRET': process.env.VUE_APP_SOCKET_APP_SECRET,
          }
      })

      socket.on('connect', (err) => {
          console.log(socket.id)
      })

      socket.on('connect_error', (err) => {
          console.log(err.message)
      })

      socket.on('doctor:update', async (data) => {
        const resDoc = await API.get("/doctors?_sort=is_online:DESC");
        this.doctors = resDoc.data;
      })
    },
    async init() {
      this.initSocket();
      this.getConsultation();
      // await this.setActiveConsultation({
      //   userID: this.currentCustomer.user.id,
      // });
      if (this.consultation) {
        if (this.consultation.status == "pending") {
          this.showModalRequest = true;
          this.clickDoctor(this.consultation.doctor);
          this.showRequestTime = true;
          this.countRequestTimer();
        } else if (this.consultation.status == "completed") {
          this.$router.push({
            path: "/e-prescription/consultation",
          });
        }
      } else {
        this.intervalDoc();
        localStorage.removeItem("timer_request_doc");
      }
    },

    async handleBeforeUnload(event) {
      const message = "Your consultation is about to be cancelled!";
      event.returnValue = message; // For most browsers

      //cancel the request if exist
      if(this.consultation)
        await this.forceCancelRequestConsultation()

      return message; // For some older browsers
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    clearInterval(this.requestCount);
    clearInterval(this.redirectTimer);
    // clearInterval(this.callDoc);
    clearInterval(this.callConsultation);
    this.init();
  },
  async beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);

    clearInterval(this.requestCount);
    clearInterval(this.redirectTimer);
    // clearInterval(this.callDoc);
    clearInterval(this.callConsultation);
  },
};
</script>

<style scoped>
.icon-close {
  font-size: 4rem !important;
  line-height: 4rem !important;
  text-align: center;
  color: red;
}
.content-custom-body {
  padding: 30px;
}
.img-container-profile {
  height: 120px;
  width: 120px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.img-container-profile img {
  height: auto;
  width: 100%;
}
@media (max-width: 425px) {
  .box-container {
    width: 300px !important;
  }
}

@media (min-width: 600px) {
  .box-container {
    width: 500px !important;
  }
}

@media (min-width: 1080px) {
  .box-container {
    width: 600px !important;
  }
}
.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}
.img-thumbnails {
  border-width: 3px !important;
  height: 62px;
  width: 62px;
  position: relative;
  display: block;
  background-color: #d9d9d9;
}

.img-thumbnails img {
  height: auto;
  width: 100%;
}

.fixed-height {
  height: calc(100vh - 254px);
  overflow-y: scroll;
}

@media (max-width: 451px) {
  .fixed-height {
    height: calc(100vh - 280px);
    overflow-y: scroll;
  }
}

@media (max-width: 425px) {
  .fixed-height {
    height: calc(100vh - 305px);
    overflow-y: scroll;
  }
}

.custom-container {
  height: calc(100vh - 142px);
}

@media print {
  .custom-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-card {
    width: 100%;
  }
}

.img-container {
  height: 150px;
  width: 100px;
}

.img-container img {
  height: 100%;
  width: auto;
}

::-webkit-scrollbar {
  display: none !important;
}

.custom-container::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.custom-container {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.floating-btn {
  top: 12px;
}

.custom-card {
  width: 600px;
}

.custom-card-sm {
  min-width: 300px;
  max-width: 350px;
}

.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}

.btn-cancel-req {
  color: #f97066;
  font-size: 1rem;
}

.table {
  border: 0px;
}

.table.table-custom thead {
  background-color: #f9fafb;
}

.table.table-custom thead th {
  border: 0px;
}

.doctor-info {
  line-height: 0.5;
}
.doctor-info span {
  font-size: 12px;
  color: #6b6c6d;
}

tr {
  cursor: pointer;
}

.modal-body {
  transition: 500ms cubic-bezier(1, 0.02, 0.65, 1) !important;
}

@media (max-width: 500px) {
  .floating-btn {
    top: 0;
    width: 100%;
    margin-bottom: 20px;
    position: relative !important;
  }
  .custom-card-sm {
    min-width: 300px;
    max-width: 350px;
  }
}

.rounded-custom {
  border-radius: 0.8rem !important;
}
.dot {
  height: 14px;
  width: 14px;
  top: 0.6rem;
  left: 0.6rem;
}
</style>